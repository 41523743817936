import React, { useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth, SignedIn, UserButton, ClerkProvider } from '@clerk/clerk-react';
import ChatsPage from "./ChatsPage/ChatsPage";
import LoginPage from "./LoginPage/LoginPage";
import styled from 'styled-components';
import * as API from "../Domain/API";

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const InitializeApi = () => {
  const { getToken } = useAuth();

  useEffect(() => {
    API.initialize(); // set base url
    API.setTokenGetter(getToken); // set token getter
  }, [getToken]);

  return null;
}

const App: React.FC = () => {
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <Router>
        <InitializeApi/>
        <AppContent />
      </Router>
    </ClerkProvider>
  );
}

export default App;

const AppContent: React.FC = () => {
  const { isLoaded, isSignedIn } = useAuth();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <AppContainer>
      <Header>
        <Title>🐕‍🦺 AskSchmidt</Title>
        <SignedIn>
          <UserButton afterSignOutUrl="/login" />
        </SignedIn>
      </Header>

      <Content>
        <Routes>
          <Route path="/login" element={
            isSignedIn ? <Navigate to="/" replace /> : <LoginPage />
          } />
          <Route path="/*" element={
            isSignedIn ? (
              <Routes>
                <Route path="/" element={<ChatsPage />} />
                <Route path="/chats" element={<ChatsPage />} />
                <Route path="/chats/:chatId" element={<ChatsPage />} />
              </Routes>
            ) : <Navigate to="/login" replace />
          } />
        </Routes>
      </Content>

      <Footer>
        <FooterText>(c) AskSchmidt 2024</FooterText>
      </Footer>
    </AppContainer>
  );
}

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Header = styled.div`
  background-color: #3b82f6;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

const Title = styled.h1`
  font-size: 1.1rem;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: #e5e7eb;
  overflow: hidden;
`;

const Footer = styled.div`
  background-color: #374151;
  color: white;
  padding: 0 16px;
`;

const FooterText = styled.p`
  font-size: 0.8rem;
`;